import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Icons
import { FaPaperclip, FaTrashAlt, FaShare, FaCheck } from 'react-icons/fa';

// Components
import Checkbox from 'components/Checkbox/Checkbox';
import Modal from 'components/Modal/Modal';
import RemoveProblemConfirmModal from 'components/RemoveProblemConfirmModal/RemoveProblemConfirmModal';

// Actions
import {
  setRepairProblemSolved,
  setRepairReturnProblem,
  setRepairTakeoffProblem,
  uploadProblemDocument,
} from 'actions';

// Misc
import { browseServer } from 'helpers/file-manager';

// Styles
import './TableRepairProblems.scss';

const TableRepairProblems = ({
  canEdit,
  open,
  finished,
  userRole,
  problems,
  repairId,
  dispatch,
}) => {
  const [removeProblemId, setRemoveProblem] = useState(null);
  const [isRemoveProblemConfirmActive, setRemoveProblemConfirmActive] = useState(false);

  const activateRemoveProblemConfirmModal = problem => {
    setRemoveProblem(problem);
    setRemoveProblemConfirmActive(true);
  };

  const deactivateRemoveProblemConfirmModal = () => {
    setRemoveProblem(null);
    setRemoveProblemConfirmActive(false);
  };

  const checkProblemSolved = (id, problemId) => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm('Вы подтверждаете, что проблема решена?')) {
      dispatch(setRepairProblemSolved(id, problemId))
        .then(() => {})
        .catch(() => {});
    }
  };

  const handleReturnProblem = (id, problemId) => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    const comment = prompt(
      'Вы подтверждаете, что хотите отправить заявку на МТР на доработку? (Введите пояснение для мастера)',
    );

    if (comment) {
      dispatch(setRepairReturnProblem(id, problemId, comment))
        .then(() => {})
        .catch(() => {});
    }
  };

  const handleTakeoffProblem = (id, problemId) => {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm('Вы подтверждаете, что хотите снять проблему?')) {
      dispatch(setRepairTakeoffProblem(id, problemId))
        .then(() => {})
        .catch(() => {});
    }
  };

  return (
    <table className="table-repair-problems table table-bordered table-striped">
      <thead>
        <tr>
          <th>Проблема</th>
          <th>Постановщик</th>
          <th>Исполнитель</th>
          <th>Срок</th>
          <th>Документы</th>
          <th>Отметка о выполнении</th>
          {canEdit && open && !finished && <th> </th>}
        </tr>
      </thead>
      <tbody>
        {problems.map(problem => (
          <tr key={problem.id}>
            <td>{problem.name}</td>
            <td>{problem.author}</td>

            {problem.targetid && (
              <td>
                {problem.target} <span className="label label_primary">{problem.role}</span>
              </td>
            )}

            {!problem.targetid && (
              <td>
                {problem.targetdepart &&
                  Array.isArray(problem.targetdepart) &&
                  problem.targetdepart.map(depart => (
                    <React.Fragment key={depart.id}>{depart.name}</React.Fragment>
                  ))}
              </td>
            )}

            <td>{problem.dateend_1}</td>

            <td style={{ width: '200px' }}>
              {/* UPLOAD DOCS */}

              {/* eslint-disable react/no-array-index-key */}
              {problem.files &&
                Array.isArray(problem.files) &&
                problem.files.map((fileItem, index) => (
                  <div className="upload-docs" key={index}>
                    <button
                      type="button"
                      className="btn btn-xs btn-primary"
                      onClick={() => {
                        browseServer({
                          onSuccess: fileUrl => {
                            dispatch(
                              uploadProblemDocument(repairId, problem.id, index, fileUrl),
                            ).catch(() => {});
                          },
                        });
                      }}
                    >
                      <FaPaperclip className="icon" />
                    </button>{' '}
                    {fileItem.file !== '' && (
                      <React.Fragment>
                        <a
                          href={fileItem.file}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="label label_info"
                        >
                          {fileItem.shortfile}
                        </a>{' '}
                        <button
                          type="button"
                          className="btn btn-danger btn-xs"
                          onClick={() => {
                            dispatch(
                              uploadProblemDocument(repairId, problem.id, index, 'fake', 'delete'),
                            ).catch(() => {});
                          }}
                        >
                          <FaTrashAlt className="icon" />
                        </button>
                      </React.Fragment>
                    )}
                  </div>
                ))}
              {/* eslint-enable react/no-array-index-key */}

              {/* UPLOAD DOCS END */}
            </td>

            <td>
              {(problem.canEdit || userRole === 10 || userRole === 14) && (
                <Checkbox
                  onClick={() => checkProblemSolved(repairId, problem.id)}
                  checked={problem.solved}
                  disabled={problem.solved}
                  ariaLabel="Поставить отметитку, что проблема решена"
                />
              )}

              {!problem.canEdit && userRole !== 10 && userRole !== 14 && (
                <Checkbox
                  onClick={() => {}}
                  checked={problem.solved}
                  disabled
                  ariaLabel="Поставить отметитку, что проблема решена"
                />
              )}

              {(userRole === 6 || userRole === 10 || userRole === 14) &&
                problem.targetdepart &&
                Array.isArray(problem.targetdepart) &&
                problem.targetdepart[0].id === 6 &&
                !problem.solved &&
                problem.name === 'Заявка на МТР' && (
                  <React.Fragment>
                    {' '}
                    <button
                      type="button"
                      className="btn btn-xs btn-danger"
                      title="Вернуть проблему постановщику"
                      onClick={() => handleReturnProblem(repairId, problem.id)}
                    >
                      <FaShare className="icon" />
                    </button>{' '}
                    <button
                      type="button"
                      className="btn btn-xs btn-warning"
                      title="Снять проблему (МТР не нужно)"
                      onClick={() => handleTakeoffProblem(repairId, problem.id)}
                    >
                      <FaCheck className="icon" />
                    </button>
                  </React.Fragment>
                )}
            </td>

            {canEdit && open && !finished && (
              <td>
                <button
                  type="button"
                  className="btn btn-danger btn-xs"
                  title="Удалить"
                  onClick={() => activateRemoveProblemConfirmModal(problem.id)}
                >
                  <FaTrashAlt className="icon" />
                </button>
              </td>
            )}
          </tr>
        ))}

        {isRemoveProblemConfirmActive && canEdit && (
          <Modal title="Удаление проблемы" closeHandler={deactivateRemoveProblemConfirmModal}>
            <RemoveProblemConfirmModal
              removeProblemId={removeProblemId}
              repairId={repairId}
              closeHandler={deactivateRemoveProblemConfirmModal}
            />
          </Modal>
        )}
      </tbody>
    </table>
  );
};

TableRepairProblems.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  finished: PropTypes.bool.isRequired,
  userRole: PropTypes.number.isRequired,
  repairId: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
  problems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      author: PropTypes.string,
      targetid: PropTypes.number,
      target: PropTypes.string,
      solved: PropTypes.bool,
      dateend_1: PropTypes.string,
      targetdepart: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
        }),
      ),
      files: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          file: PropTypes.string,
          shortfile: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

export default connect()(TableRepairProblems);
