import { fetchWrapper } from 'helpers/fetch-wrapper';
import * as types from 'actions/action-types';

/**
 * User adds a new location
 */

export const addLocation = (
  parentId,
  {
    name,
    //dateready,
    ksid,
    object,
    city,
  },
  isCustom,
) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}location/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify(
        Object.assign(
          {},
          isCustom
            ? {
                city,
                isCustom: 1,
              }
            : {
                // dateready,
                ksid,
                object,
                city,
              },
          parentId && { parentId },
        ),
      ),
    })
      .then(resp => {
        dispatch({
          type: types.ADD_LOCATION_SUCCESS,
          payload: {
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_LOCATION_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * User fetches all despatches
 */

export const loadLocations = query => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}location/${query ? '?q=' + query : ''}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
    })
      .then(resp => {
        dispatch({
          type: types.LOAD_LOCATIONS_SUCCESS,
          meta: Object.assign({}, query && { isCustomSearch: true }),
          payload: {
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.LOAD_LOCATIONS_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * User adds a new transport
 */

export const addTransport = ({ name, carNumber, liftingCapacity, passengers }) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}transport/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify(
        Object.assign(
          {},
          {
            // dateready,
            name,
            carnumber: carNumber,
            liftingcapacity: liftingCapacity,
            passengers,
          },
        ),
      ),
    })
      .then(resp => {
        dispatch({
          type: types.ADD_TRANSPORT_SUCCESS,
          payload: {
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_TRANSPORT_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * User fetches all transports
 */

export const loadTransport = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}transport/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
    })
      .then(resp => {
        dispatch({
          type: types.LOAD_TRANSPORT_SUCCESS,
          payload: {
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.LOAD_TRANSPORT_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * User fetches all drivers
 */

export const loadDrivers = () => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}drivers/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
    })
      .then(resp => {
        dispatch({
          type: types.LOAD_DRIVERS_SUCCESS,
          payload: {
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.LOAD_DRIVERS_FAIL,
        });
        reject(error);
      });
  });
};

/**
 * User adds a new driver
 */

export const addDriver = ({ name }) => dispatch => {
  const url = `${process.env.REACT_APP_API_URL}drivers/`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify(
        Object.assign(
          {},
          {
            name,
          },
        ),
      ),
    })
      .then(resp => {
        dispatch({
          type: types.ADD_DRIVER_SUCCESS,
          payload: {
            resp,
          },
        });
        resolve(resp);
      })
      .catch(error => {
        dispatch({
          type: types.ADD_DRIVER_FAIL,
        });
        reject(error);
      });
  });
};
