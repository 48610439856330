import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ModalHeader from 'components/ModalHeader/ModalHeader';
import ModalBody from 'components/ModalBody/ModalBody';
import ModalError from 'components/ModalError/ModalError';
import ModalFooter from 'components/ModalFooter/ModalFooter';
import FormGroup from 'components/FormGroup/FormGroup';
import InputForm from 'components/InputForm/InputForm';
import SubmitButton from 'components/SubmitButton/SubmitButton';

// Actions
// import { addTransport } from 'actions';

// Helpers
import { addDriver } from "../../actions/common";

const AddDriverFormModal = ({ dispatch, closeHandler, user }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [userName, setUserName] = useState(null);
    const [fieldErrors, setFieldErrors] = useState({});
    const [responseError, setResponseError] = useState(null);

    const handleSubmitDriver = () => {
        /** Показываем прелоадер на кнопку */
        setIsLoading(true);

        let params = {
            name: userName,
        };

        /** Записываем нового водителя в базу данных */
        dispatch(addDriver(params))
            .then(() => {
                /** Скрываем прелоадер на кнопку */
                setIsLoading(false);

                /** Закрываем модалку */
                closeHandler();
            })
            .catch(error => {
                /** Если есть ошибки, выводим */
                if (error && error.errorData && Object.keys(error.errorData).length) {
                    setFieldErrors(error.errorData);
                } else if (error && error.error) {
                    setResponseError(error.error);
                } else {
                    setResponseError('Произошла неизвестная ошибка');
                }

                /** Скрываем прелоадер на кнопку */
                setIsLoading(false);
            });
    };

    // const canSubmit = () => {
    //     return (userName.length > 3);
    // };

    return (
        <React.Fragment>
            <ModalHeader>
                <h4 style={{ marginRight: 8 }}>Добавление водителя</h4>
            </ModalHeader>

            <ModalBody>

                <FormGroup title="Водитель" error={fieldErrors.name}>
                    <InputForm
                        type="text"
                        value={userName}
                        placeholder="Введите имя водителя"
                        onChange={({ target }) => setUserName(target.value)}
                    />
                </FormGroup>

            </ModalBody>

            {responseError && <ModalError className="modal-error_border">{responseError}</ModalError>}

            <ModalFooter closeHandler={closeHandler}>
                <button type="button" className="btn btn-default" onClick={closeHandler}>
                    Закрыть
                </button>

                <SubmitButton
                    // isValid={canSubmit()}
                    isLoading={isLoading}
                    isLoadingText="Создаю..."
                    clickHandler={handleSubmitDriver}
                >
                    Создать
                </SubmitButton>
            </ModalFooter>
        </React.Fragment>
    );
};

AddDriverFormModal.propTypes = {
    dispatch: PropTypes.func.isRequired,
    closeHandler: PropTypes.func.isRequired,
    repairId: PropTypes.number.isRequired,
    user: PropTypes.array.isRequired,
};

export default connect()(AddDriverFormModal);
